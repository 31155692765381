import React from 'react';
import service_capacity from 'images/service_capacity.png';
import UsesSection from 'components/sections/UsesSection';




function Operations() {

    return (
        <UsesSection
            title={"Planner/Designer"}
            content="Key Driver, Space Program(Summary, Space Map, Room List Adjacency Matrix)"
            link_backward="/uses/finance"
            link_forward="/uses/strategy"
            media={<div className="uses-video">
                <video
                    className="uses-video"
                    canplay="this.play()"
                    onloadeddata="this.play();"
                    playsinline
                    id="info-video"
                    title="Video"
                    onclick="this.play()"
                    controls="controls"
                    loop
                >
                    <source type="video/mp4" src="https://storage.googleapis.com/media-buckets-prod-forsightdigital-com/videos/PDesigner_Short.mp4"></source>
                </video>

            </div>}
        />
    )
}

export default Operations;